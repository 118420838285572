



















































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import type { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiCard from '@/components/ui/UiCard.vue';
import UiLoading from '@/components/ui/UiLoading.vue';

import Chart from '../components/Chart.vue';
import WalletItem from '../components/dashboard/WalletItem.vue';

@Component({
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            bulkSend {
              enabled
              canEnable
            }
            balance {
              balances {
                coin
                value
                displayValue
                fiatValue {
                  value
                  displayValue
                  currency
                }
              }
              totalFiatValue {
                displayValue
                currency
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
  components: {
    WalletItem,
    Chart,
    UiLoading,
    UiCard,
  },
})
export default class Dashboard extends BaseVue {
  wallets: Wallet[] = [];
  totalBalance = '0';
  graphCycles: string[] = ['1D', '5D', '1W', '1M'];
  portfolioCycle = '1M';
  transactionCycle = '1W';
  balanceData: any = null;
  transactionData: any = null;
  assetsLiabilities: any = null;
  defiBalances: any = null;
  defiByProtocol: any = null;
  exposureByNetwork: any = null;

  $refs!: {
    balanceChart: any;
    defiBalanceChart: any;
    assetsLiabilities: any;
  };

  blockchains: any = null;

  announcements: any = null;

  public async getAnnouncements() {
    await this.simulateApiCall();
    this.announcements = ['Checkout our New Bitwave Market Place', 'EDAS is approaching did you get you ticket?'];
  }

  public async getBlockchains() {
    await this.simulateApiCall();
    this.blockchains = [
      {
        value: 'BTC',
        icon: require('../assets/blockchains/btc.svg'),
        label: 'Bitcoin',
        description: 'BTC',
        balance: '407K',
        percentage: '12.5%',
      },
      {
        value: 'ETH',
        icon: require('../assets/blockchains/eth.svg'),
        label: 'Ethereum',
        description: 'ETH',
        balance: '677K',
        percentage: '25.0%',
      },
      {
        value: 'EOS',
        icon: require('../assets/blockchains/eos.png'),
        label: 'EOS Network',
        description: 'EOS',
        balance: '814K',
        percentage: '30.0%',
      },
      {
        value: 'DASH',
        icon: require('../assets/blockchains/dash.png'),
        label: 'DASH Network',
        description: 'DASH',
        balance: '814K',
        percentage: '30.0%',
      },
    ];
  }

  public async simulateApiCall(time = 2000) {
    console.log('API call started');

    await new Promise((resolve) => {
      setTimeout(() => {
        console.log('API call finished');
        resolve('API Call Result');
      }, time); // Wait 2000ms (2 seconds)
    });

    console.log('Continue after API call');
  }

  public async getBalanceData() {
    const ctx = this.$refs.balanceChart.$el.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
    gradient.addColorStop(0, 'rgba(184, 220, 255, 0.34)');
    gradient.addColorStop(1, 'rgba(226, 233, 255, 0)');
    // make call
    await this.simulateApiCall(2000);
    this.balanceData = {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
          {
            label: 'Portfolio',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: gradient,
            borderColor: '#006AFF',
            tension: 0.2,
            fill: true,
          },
        ],
      },
      options: {
        pointRadius: 3,
        maintainAspectRatio: false,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: '#006AFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBorderWidth: 1,
        pointHitRadius: 30,
        borderWidth: 1.5,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };
  }

  public async getDefiBalances() {
    const ctx = this.$refs.defiBalanceChart.$el.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
    const gradient2 = ctx.createLinearGradient(0, 0, 0, 500);

    gradient.addColorStop(0, 'rgba(184, 220, 255, 0.34)');
    gradient.addColorStop(1, 'rgba(226, 233, 255, 0)');
    gradient2.addColorStop(0, 'rgba(58, 192, 143, 0.34)');
    gradient2.addColorStop(1, 'rgba(58, 192, 143, 0)');
    await this.simulateApiCall(4000);
    this.defiBalances = {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Aave',
            data: [420, 172, 339, 146, 355, 240, 461, 356, 371, 251, 275, 427],
            backgroundColor: gradient,
            borderColor: '#00A7F8',
            tension: 0.2,
            fill: true,
          },
          {
            label: 'Compound',
            data: [675, 600, 550, 600, 550, 800, 700, 650, 700, 600, 650, 690],
            backgroundColor: gradient,
            borderColor: '#A2D2E8',
            tension: 0.2,
            fill: true,
          },
          {
            label: 'UniSwap',
            data: [32, 131, 64, 141, 57, 104, 15, 167, 51, 99, 198, 225],
            backgroundColor: gradient2,
            borderColor: '#3AC08F',
            tension: 0.2,
            fill: true,
          },
        ],
      },
      options: {
        pointRadius: 3,
        maintainAspectRatio: false,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: '#006AFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBorderWidth: 1,
        pointHitRadius: 30,
        borderWidth: 1.5,
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
          },
        },
      },
    };
  }

  public async getDefiProtocol() {
    await this.simulateApiCall(3000);
    this.defiByProtocol = {
      type: 'doughnut',
      data: {
        labels: ['BTC', 'ETH', 'EOS', 'DASH'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [407, 677, 814, 814],
            backgroundColor: ['#3AC08F', '#00A7F8', '#5FC6D0', '#A2D2E8', '#C4E4CF', '#7DBB9A', '#96D1E1'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        radius: 70,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  }

  public async getExposureByNetwork() {
    await this.simulateApiCall(2200);
    this.exposureByNetwork = {
      type: 'doughnut',
      data: {
        labels: ['UniSwap', 'Aave', 'Compound'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [225, 427, 690],
            backgroundColor: ['#3AC08F', '#00A7F8', '#5FC6D0', '#A2D2E8', '#C4E4CF', '#7DBB9A', '#96D1E1'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        radius: 70,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  }

  public async getAssetsLiabilities() {
    await this.simulateApiCall(1000);
    this.assetsLiabilities = {
      type: 'bar',
      data: {
        labels: ['Aave', 'Compound', 'UniSwap'],
        datasets: [
          {
            label: 'Principal',
            data: [390, 458, 225],
            backgroundColor: '#007EFF',
          },
          {
            label: 'Liabilites',
            data: [37, 151, 0],
            backgroundColor: '#3AC08F',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
        responsive: true,
        barThickness: 20,
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    };
  }

  async mounted() {
    await this.$nextTick();
    this.getBalanceData();
    this.getDefiBalances();
    this.getAssetsLiabilities();
    this.getExposureByNetwork();
    this.getDefiProtocol();
    this.getBlockchains();
    this.getAnnouncements();
  }

  @Watch('wallets')
  walletUpdated(val: any) {
    this.totalBalance = this.wallets
      .reduce(
        (tVal, wallet) =>
          tVal + (wallet.balance?.balances?.reduce((total: number, cur: any) => total + cur.fiatValue.value, 0) ?? 0),
        0
      )
      .toFixed(2);
  }
}
